import {
  useFetch,
  ResponseWithId,
  ResponseWithSuccess,
  createUrl,
  PaginatedApiResponse,
  fixPagination,
  DateMeta,
  download,
} from './fetch'
import { QuestionType, QuestionTypes, Target } from '../components/question/question'
import { BlockType } from '../components/check/block'
import { YesNo } from '../components/question/questionYesNo'
import { Formula } from '../components/question/questionFormula'
import { Conditions } from '../components/question/questionCondition'
import { FileMeta, FileMetaUpdate } from './files'
import { Meta } from '../components/check/checkMetaForm'
import Strings from '../components/strings'

export type Check = {
  id: string
  title: string
  norm: string
  description: string
  active: boolean
  categories: string[]
  documentTitle: string
  logoId: string
  pdfLabels: {
    title: string
    norm: string
    user: {
      product: string
      createdBy: string
    }
    result: string
    questions: string
    documents: string
  }
  valid: boolean
  blocks: BlockType[]
} & DateMeta

const relativUrl = '/v1/checks'

export async function getChecks(
  token?: string,
  page = 0,
  perPage = 25,
  filter?: { searchValue?: string; categoryIds?: string; userId?: string; sortBy?: string; sortDirection?: string },
): Promise<PaginatedApiResponse<Check>> {
  const url = createUrl(`${relativUrl}`)
  url.searchParams.set('page', (page + 1).toString())
  url.searchParams.set('perPage', perPage.toString())

  if (filter && filter.searchValue) {
    url.searchParams.set('fulltext', filter.searchValue)
  }
  if (filter && filter.categoryIds) {
    url.searchParams.set('categoryIds', filter.categoryIds)
  }
  if (filter && filter.userId) {
    url.searchParams.set('userId', filter.userId)
  }
  if (filter && filter.sortBy && filter.sortDirection) {
    url.searchParams.set('sortBy', filter.sortBy)
    url.searchParams.set('sortDirection', filter.sortDirection)
  }

  return useFetch({ token, url, method: 'GET' }).then(fixPagination)
}

export async function getChecksById(ids: string[]): Promise<Check[]> {
  const url = createUrl(`${relativUrl}`)

  url.searchParams.set('page', '1')
  url.searchParams.set('perPage', '-1')
  url.searchParams.set('checkIds', ids.join())

  if (!ids.length) {
    return new Promise((resolve, reject) => {
      resolve([])
    })
  }

  return useFetch({ url, method: 'GET' }).then((response: PaginatedApiResponse<Check>) => {
    return response.data
  })
}

export async function getCheck(token: string, id: string): Promise<Check> {
  const url = createUrl(`${relativUrl}/${id}`)
  return useFetch({ token, url, method: 'GET' })
}

export async function createCheck(token: string, values: Record<string, any>): Promise<ResponseWithId> {
  const url = createUrl(`${relativUrl}`)
  return useFetch({ token, url, method: 'POST', body: values })
}

export async function updateCheck(token: string, id: string, values: Record<string, any>): Promise<ResponseWithId> {
  const url = createUrl(`${relativUrl}/${id}`)
  return useFetch({ token, url, method: 'PUT', body: values })
}

export async function copyCheck(token: string, id: string): Promise<ResponseWithId> {
  const url = createUrl(`${relativUrl}/${id}/copy`)
  return useFetch({ token, url, method: 'POST' })
}

export async function deleteCheck(token: string, id: string): Promise<ResponseWithSuccess> {
  const url = createUrl(`${relativUrl}/${id}`)
  return useFetch({ token, url, method: 'DELETE' })
}

export async function getCheckFiles(token: string, checkId: string): Promise<FileMeta[]> {
  const url = createUrl(`${relativUrl}/${checkId}/files`)
  return useFetch({ token, url, method: 'GET' })
}

export async function getCheckPdf(token: string, id: string) {
  const url = createUrl(`${relativUrl}/${id}/pdf`)
  return download({ token, url })
}

export async function uploadCheckFile(
  token: string,
  checkId: string,
  fileMeta: FileMetaUpdate & { file: File },
): Promise<ResponseWithId> {
  const url = createUrl(`${relativUrl}/${checkId}/files`)
  const formData = new FormData()
  formData.set('file', fileMeta.file)
  if (fileMeta.name) {
    formData.set('name', fileMeta.name)
  }

  if (fileMeta.description) {
    formData.set('description', fileMeta.description)
  }

  return useFetch({ token, url, method: 'POST', body: formData, withoutJson: true })
}

export async function deleteCheckFiles(token: string, checkId: string, fileId: string): Promise<ResponseWithSuccess> {
  const url = createUrl(`${relativUrl}/${checkId}/files/${fileId}`)
  return useFetch({ token, url, method: 'DELETE' })
}

export function getQuestion(uid: string | null, blocks: BlockType[]) {
  let theQuestion = {} as QuestionType<QuestionTypes>

  if (uid) {
    blocks.forEach(block => {
      block.questions.forEach(question => {
        if (question.uid === uid) {
          theQuestion = question
        }
      })
    })
  }

  return theQuestion
}

export function getQuestionPosition(uid: string | null, blocks: BlockType[]) {
  let theQuestion = [0, 0]

  if (uid) {
    blocks.forEach((block, blockIndex) => {
      block.questions.forEach((question, questionIndex) => {
        if (question.uid === uid) {
          theQuestion = [blockIndex, questionIndex]
        }
      })
    })
  }

  return theQuestion
}

export function getQuestionTypeDisplayName(type: string, blockIndex: number, questionIndex: number) {
  const typeName = () => {
    switch (type) {
      case 'condition':
        return Strings.de.components.question.conditionQuestion
      case 'formula':
        return Strings.de.components.question.formulaQuestion
      default:
        return Strings.de.components.question.yesNoQuestion
    }
  }
  return `${blockIndex + 1}.${questionIndex + 1}. ${typeName()}`
}

export function existsInBlocks(blocks: BlockType[], uid: string | null) {
  let result = false
  if (uid && blocks) {
    blocks.forEach(block => {
      block.questions.forEach(question => {
        if (question.uid === uid) {
          result = true
        }
      })
    })
  }

  return result
}

export function validateCheckLinks(question: QuestionType<QuestionTypes>, blocks: BlockType[]) {
  const hasTarget = (target: Target) => {
    return !!(target && (target.end || (target.link && existsInBlocks(blocks, target.link))))
  }

  if (question.type === 'yesNo') {
    const yesNoQuestion = question as QuestionType<YesNo>
    return hasTarget(yesNoQuestion.logic.yes) && hasTarget(yesNoQuestion.logic.no)
  }
  if (question.type === 'formula') {
    const formulaQuestion = question as QuestionType<Formula>
    return (
      hasTarget(formulaQuestion.logic.gt) && hasTarget(formulaQuestion.logic.lt) && hasTarget(formulaQuestion.logic.eq)
    )
  }
  if (question.type === 'condition') {
    const { conditions } = (question as QuestionType<Conditions>).logic

    if (conditions) {
      const isLinked = conditions.filter(condition => {
        return hasTarget(condition)
      })

      return isLinked.length === conditions.length
    }
  }

  return false
}
