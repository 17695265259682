import React from 'react'
import Logo from '../logo'
import Link from '../link'
import { IconCart } from '../icons'
import { isLoggedIn } from '../../utils/auth'
import { useEffect, useState } from 'react'
import Strings from '../strings'

const Header = ({ headerMenu, token }) => {
  const [cartValue, setCartValue] = useState(0)

  useEffect(() => {
    setCartValue(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')!).length : 0)

    document.addEventListener('cartChange', handleCartChange)

    return () => {
      document.removeEventListener('cartChange', handleCartChange)
    }
  }, [])

  const handleCartChange = () => {
    setCartValue(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')!).length : 0)
  }

  return (
    <header className="w=viewport c=text header elevated">
      <div className="w=site mx py=4 flex-grid align-center">
        <Link href="/">
          <a className="w=1/4">
            <Logo />
          </a>
        </Link>
        <div className="w=1/4 text-align=r flex justify-end align-center">
          <Link activeClassName="link__active c=prim-alt" href={isLoggedIn(token) ? '/app' : '/login'}>
            <a className="ml px=3 py=2 justify-end r-circle c=prim-alt b ">
              {isLoggedIn(token) ? Strings.de.components.cms.toApp : Strings.de.components.cms.login}
            </a>
          </Link>
          <Link activeClassName="link__active" href="/cart">
            <a className="px=2 py=1 c=prim flex r-circle justify-end align-center p=1 cart ml=1 relative">
              <IconCart />
              {!!(cartValue > 0) && (
                <span className="c=err-alt r-circle absolute flex align-center justify-center text-s notification text-align=c">
                  {cartValue}
                </span>
              )}
            </a>
          </Link>
        </div>
      </div>
      <style jsx>{`
        .header {
          // background-color: rgba(255,255,255,0.06);
        }
        .cart {
          width: 48px;
          height: 48px;
        }
        .notification {
          min-width: 20px;
          height: 20px;
          top: -5px;
          left: calc(100% - 15px);
          padding: 8px;
        }
      `}</style>
    </header>
  )
}

export default Header
