import React, { FunctionComponent } from 'react'

type Props = {
  fullOverlay?: boolean
}

const LoadingSpinner: FunctionComponent<Props> = ({ fullOverlay = false }) => {
  return (
    <div className={fullOverlay ? 'overlay' : ''}>
      <div className={`loader ${fullOverlay ? 'center' : ''}`}>Lade...</div>
      <style jsx>{`
        .overlay {
          background-color: rgba(235, 236, 236, 0.9);
          height: 100%;
          width: 100%;
          position: fixed;
          z-index: 5;
        }

        .loader,
        .loader:before,
        .loader:after {
          border-radius: 50%;
          width: 2.5em;
          height: 2.5em;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation: load7 1.8s infinite ease-in-out;
          animation: load7 1.8s infinite ease-in-out;
        }

        .loader {
          color: #45a582;
          font-size: 4px;
          margin: 20px auto;
          position: relative;
          text-indent: -9999em;
          -webkit-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
        }

        .loader:before,
        .loader:after {
          content: '';
          position: absolute;
          top: 0;
        }

        .loader:before {
          left: -3.5em;
          -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
        }

        .loader:after {
          left: 3.5em;
        }

        .loader.center {
          margin: 0;
          left: 50%;
          top: 50%;
        }

        @-webkit-keyframes load7 {
          0%,
          80%,
          100% {
            box-shadow: 0 2.5em 0 -1.3em;
          }
          40% {
            box-shadow: 0 2.5em 0 0;
          }
        }

        @keyframes load7 {
          0%,
          80%,
          100% {
            box-shadow: 0 2.5em 0 -1.3em;
          }
          40% {
            box-shadow: 0 2.5em 0 0;
          }
        }
      `}</style>
    </div>
  )
}

export default LoadingSpinner
