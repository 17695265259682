import { useFetch, ResponseWithId, createUrl, ResponseWithSuccess } from './fetch'

export type Menu = {
  id: string
  pageId: string
  title: string
  menuName: string
  menuOrder: number
  slug: string
}

export type MenuUpdate = {
  pageId?: string
  title?: string
  menuName?: string
  menuOrder?: number
}

const baseUrl = '/v1/menus'

export async function getMenu(id: string): Promise<Menu[]> {
  const url = createUrl(`${baseUrl}/${id}`)
  return useFetch({ url, method: 'GET' })
}

export async function createMenu(token: string, item: MenuUpdate): Promise<ResponseWithId> {
  const url = createUrl(`${baseUrl}`)
  return useFetch({ token, url, method: 'POST', body: item })
}

export async function updateMenu(token: string, id: string, item: MenuUpdate): Promise<ResponseWithId> {
  const url = createUrl(`${baseUrl}/${id}`)
  return useFetch({ token, url, method: 'PUT', body: item })
}

export async function deleteMenu(token: string, id: string): Promise<ResponseWithSuccess> {
  const url = createUrl(`${baseUrl}/${id}`)
  return useFetch({ token, url, method: 'DELETE' })
}
